/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }
  .rc-form .file-upload input[type="file"] {
    display: none; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	CONTENT BLOCK OVERRIDES
 */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rich-content {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto; }
  .rich-content .rc-block:last-child {
    margin-bottom: 0; }
    .rich-content .rc-block:last-child > *:last-child {
      margin-bottom: 0; }

.rc-rte h1,
.rc-txt h1 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 300; }

.rc-rte a,
.rc-txt a {
  color: #504e53;
  font-weight: bold; }

.rc-video,
.rc-video-left,
.rc-video-right {
  /*.thumb::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(blue, 0.75);
	}*/ }

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOSTRAP OVERRIDES
 */
/* text */
.h1, h1 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 300; }

.h2, h2 {
  margin-bottom: 20px;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 300;
  color: #e11118; }

.h3, h3 {
  margin-bottom: 20px;
  font-size: 1.1rem;
  text-transform: uppercase; }

.h4, h4 {
  margin-bottom: 20px;
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase; }

.h5, h5 {
  margin-bottom: 20px;
  font-size: 0.85rem;
  color: #e11118;
  text-transform: uppercase; }

.h6, h6 {
  margin-bottom: 20px;
  font-size: 0.85rem;
  text-transform: uppercase; }

p {
  margin-bottom: 20px; }

a {
  color: #e11118; }
  a:hover, a:focus {
    color: #e11118; }
  a:active {
    color: #e11118;
    text-decoration: underline; }

button, input, optgroup, select, textarea {
  font-family: "Muli", Helvetica, Arial, sans-serif; }

/* nav */
.nav-link {
  color: #504e53; }
  .nav-link:hover {
    color: #e11118; }
  .nav-link:active {
    color: #e11118;
    text-decoration: underline; }

/* buttons */
.btn {
  border-radius: 0;
  line-height: 1.1;
  text-transform: uppercase;
  text-decoration: none !important;
  letter-spacing: .5px;
  font-weight: bold; }

.btn-sm {
  padding: .35rem .5rem;
  font-size: 0.85rem; }

.btn-primary {
  background-color: transparent;
  border: 2px solid #e11118;
  color: #e11118;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-primary:focus, .btn-primary.focus, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:focus, .btn-primary.active.focus {
    outline: none;
    box-shadow: none; }
  .btn-primary:hover, .btn-primary.hover, .btn-primary:focus {
    background-color: #e11118;
    border-color: #e11118;
    color: #fff; }
  .btn-primary:active, .btn-primary.active {
    background-color: #504e53;
    border-color: #504e53;
    color: #fff; }
  .btn-primary.disabled, .btn-primary[disabled],
  fieldset[disabled] .btn-primary {
    background-color: transparent;
    border: 2px solid #e11118;
    color: #e11118; }

.btn-secondary {
  font-weight: normal;
  background-color: transparent;
  border: 1px solid #504e53;
  color: #504e53;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-secondary:focus, .btn-secondary.focus, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:focus, .btn-secondary.active.focus {
    outline: none;
    box-shadow: none; }
  .btn-secondary:hover, .btn-secondary.hover, .btn-secondary:focus {
    background-color: #e11118;
    border-color: #e11118;
    color: #fff; }
  .btn-secondary:active, .btn-secondary.active {
    background-color: #504e53;
    border-color: #504e53;
    color: #fff; }
  .btn-secondary.disabled, .btn-secondary[disabled],
  fieldset[disabled] .btn-secondary {
    background-color: transparent;
    border: 2px solid #e11118;
    color: #e11118; }

/* forms */
.form-control {
  border-radius: 0;
  border: 2px solid #504e53;
  font-size: 0.85rem; }

.has-danger .form-control {
  border-color: #e11118; }

/* card */
.card {
  border-radius: 0; }

/* pagination */
.pagination .page-item .page-link {
  border-radius: 0 !important;
  border: 2px solid #504e53;
  color: #504e53;
  background-color: #fff;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  border-left: 0; }
  .pagination .page-item .page-link:first-child {
    border-left: 2px solid #504e53; }

.pagination .page-item.active .page-link {
  border: 2px solid #504e53;
  color: #FFFFFF;
  background-color: #504e53; }

/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* hide placeholder text on focus */
input:focus::-webkit-input-placeholder {
  color: transparent; }

input:focus:-moz-placeholder {
  color: transparent; }

input:focus::-moz-placeholder {
  color: transparent; }

input:focus:-ms-input-placeholder {
  color: transparent; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%;
  font-family: "Muli", Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  color: #504e53; }

/*
 *	UTILITIES
 */
.small-gutters {
  margin-left: -5px;
  margin-right: -5px; }
  .small-gutters > .col,
  .small-gutters > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px; }

.flex-stretch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  .flex-stretch > * {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 2px; }

/*
 *	GENERAL
 */
.with-errors {
  color: #e11118; }

.section {
  padding: 60px 0;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  background-color: #fefefe; }

.block-title {
  display: inline-block;
  margin-bottom: 75px;
  min-width: 360px;
  max-width: 100%;
  padding: 22px 20px;
  border: 2px solid #504e53;
  color: #504e53;
  background-color: #fff;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  text-align: center; }

.block-title--mb {
  margin-bottom: 30px; }

.simple-button {
  display: inline-block;
  color: #504e53;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer; }
  .simple-button:hover {
    color: #e11118;
    text-decoration: none; }
  .simple-button:active {
    color: #e11118;
    text-decoration: underline; }

/*
 *	HEADER
 */
.header {
  z-index: 1;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
  padding-bottom: 10px; }

.col--nav {
  -ms-flex-positive: 1;
  flex-grow: 1; }

.logo {
  width: 208px;
  max-width: 100%; }
  .logo a {
    display: block;
    max-width: 100%; }
  .logo img {
    max-width: 100%; }

.nav--main {
  text-transform: uppercase; }
  .nav--main .nav-item {
    margin-right: 2.5%; }

.nav--lang {
  text-transform: uppercase; }
  .nav--lang .nav-item + .nav-item {
    font-size: 0; }
    .nav--lang .nav-item + .nav-item::before {
      content: '|';
      display: inline-block;
      font-size: 0.85rem; }
    .nav--lang .nav-item + .nav-item .nav-link {
      display: inline-block;
      font-size: 0.85rem; }

/* mobile stuff */
.finger-print {
  display: none;
  width: 65px;
  margin: 15px 0 10px;
  min-height: 1px;
  /* IE */ }
  .finger-print img {
    max-width: 100%; }

.open-nav {
  display: none;
  border: 1px solid #504e53;
  background-color: transparent;
  text-transform: uppercase;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: normal; }
  .open-nav:hover, .open-nav:focus {
    color: #e11118;
    border-color: #e11118; }

.close-nav {
  display: none;
  width: 45px;
  height: 45px;
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 20px;
  padding: 0;
  cursor: pointer; }

.close-nav--top {
  position: absolute;
  top: 0;
  right: 0;
  opacity: .7; }
  .close-nav--top:hover, .close-nav--top:active {
    opacity: 1; }

/*
 *	WORK
 */
.work {
  margin-bottom: 10px;
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.work__title {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: 20px 25px 13px 25px;
  font-size: .9rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase; }
  .work__title a {
    color: #504e53;
    text-decoration: none; }
    .work__title a:hover {
      color: #e11118; }
    .work__title a:active {
      color: #e11118;
      text-decoration: underline; }

.artist__img {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-height: 1px;
  /* IE FIX */ }
  .artist__img a {
    display: block;
    width: 100%; }
  .artist__img img {
    width: 100%;
    max-width: 100%; }

.work__img {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-height: 1px;
  /* IE FIX */ }
  .work__img a {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    display: block;
    width: 100%;
    height: 200px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center; }
    .work__img a img {
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-height: 90%;
      max-width: 75%;
      width: auto !important; }

.work__desc {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 20px 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start; }
  .work__desc .center {
    width: 100%;
    text-align: center; }
  .work__desc .title {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    max-width: 100%;
    margin-bottom: 0;
    font-weight: bold;
    text-transform: uppercase; }
  .work__desc p {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    max-width: 100%; }
  .work__desc .more {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    margin-top: auto; }

.more-work {
  margin-top: 50px;
  display: inline-block;
  color: #fff;
  text-transform: uppercase; }
  .more-work:hover, .more-work:focus, .more-work:active {
    color: #fff; }

/*
 *	SHARE
 */
.share {
  margin-top: 30px; }
  .share p {
    text-transform: uppercase;
    margin-bottom: 3px;
    font-size: .75rem; }
  .share ul {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 0; }
  .share li {
    display: inline-block;
    margin: 0 3px 3px 0; }
  .share a {
    display: block;
    font-size: 18px;
    text-align: center;
    color: #504e53;
    cursor: pointer; }
  .share .instagram:hover,
  .share .instagram:active {
    color: #e4405f !important; }
  .share .facebook:hover,
  .share .facebook:active {
    color: #3b5999 !important; }
  .share .twitter:hover,
  .share .twitter:active {
    color: #00b0ed !important; }
  .share .instagram:hover,
  .share .instagram:active {
    color: #c32aa3 !important; }
  .share .pinterest:hover,
  .share .pinterest:active {
    color: #cb2027 !important; }

/*
 *	AGENDA
 */
.agenda {
  display: -ms-flexbox;
  display: flex;
  margin: 0 -15px 35px; }

.agenda__img {
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  padding: 0 15px; }

.agenda__desc {
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  padding: 0 15px; }
  .agenda__desc .title {
    margin-bottom: 25px;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase; }
  .agenda__desc .date {
    text-transform: uppercase; }

/*
 *	ARTPOOL
 */
.artpool-filters {
  padding: 50px 0 40px;
  text-align: center; }
  .artpool-filters select {
    width: auto;
    margin: 0 5px 10px;
    /*background-image: url('/img/icon-dropdown.png');
		background-size: 25px auto;
		background-position: center right;
		background-repeat: no-repeat;
		appearance: none;
		*/ }

.equal-height {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center; }
  .equal-height > * {
    margin: 10px;
    display: block;
    height: 200px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center; }
    .equal-height > * img {
      height: 100%; }

/*
 *	EVENT
 */
.event {
  margin-bottom: 15px;
  display: -ms-flexbox;
  display: flex; }

.event__date {
  text-align: right; }

.event__desc {
  padding-left: 30px; }
  .event__desc .title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0; }
  .event__desc p {
    margin-bottom: 5px; }

.event--past .event__desc .title {
  font-weight: normal; }

/*
 *	SLICKSLIDER
 */
.slickslider {
  margin: 0 0 30px;
  padding: 0; }

.slick-prev,
.slick-next {
  z-index: 50;
  position: absolute;
  top: 50%;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 30px;
  transform: translateY(-50%);
  opacity: .6;
  cursor: pointer;
  outline: none; }
  .slick-prev:focus,
  .slick-next:focus {
    color: #504e53; }
  .slick-prev:hover, .slick-prev:active,
  .slick-next:hover,
  .slick-next:active {
    color: #e11118;
    opacity: 1; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

#slider-work {
  border: 1px solid #000; }
  #slider-work a.slide-link {
    display: block;
    padding-bottom: 75%;
    position: relative; }
    #slider-work a.slide-link .img-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center; }
      #slider-work a.slide-link .img-wrapper img {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-height: 90%;
        max-width: 75%;
        width: auto !important; }

#slider-work-thumbs .slick-slide {
  margin-right: 15px;
  border: 1px solid #000; }

#slider-work-thumbs .slick-current {
  border-color: #e11118; }

#slider-work-thumbs img {
  height: 120px;
  cursor: pointer; }

@media (max-width: 1199px) {
  #slider-work-thumbs img {
    height: 90px; } }

@media (max-width: 991px) {
  #slider-work-thumbs img {
    height: 70px; } }

/*
 *	SUBSCRIBE
 */
.subscribe-form {
  width: 360px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center; }
  .subscribe-form .input {
    margin-bottom: 15px; }
  .subscribe-form select {
    width: 100%; }
  .subscribe-form .form-control {
    text-align: center; }
  .subscribe-form .simple-button {
    margin-top: 35px; }

/*
 *	FOOTER
 */
.footer {
  padding: 30px 0;
  background-color: #504e53;
  color: #fff; }
  .footer .title {
    text-transform: uppercase; }
  .footer a {
    color: #fff; }
    .footer a:hover {
      color: #f57c80;
      text-decoration: none; }
    .footer a:active {
      color: #f57c80;
      text-decoration: underline; }
  .footer ul {
    margin: 0;
    padding: 0;
    list-style: none; }

.footer-social {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: flext-start;
  align-items: flext-start; }

.footer-social__text {
  font-weight: bold;
  text-align: right;
  padding-right: 10px;
  line-height: 1.3;
  padding-top: 4px; }

.footer-social__icons {
  -ms-flex-order: 0;
  order: 0;
  font-size: 0; }
  .footer-social__icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 2rem;
    display: inline-block;
    transition: background .15s ease-out; }
    .footer-social__icons a:hover {
      background-color: #fff; }
  .footer-social__icons i::before {
    margin-left: 0;
    margin-right: 0; }
  .footer-social__icons .instagram:hover,
  .footer-social__icons .instagram:active {
    color: #e4405f; }
  .footer-social__icons .facebook:hover,
  .footer-social__icons .facebook:active {
    color: #3b5999; }

.footer__links li {
  display: inline-block; }
  .footer__links li:before {
    content: '-';
    padding: 0 4px; }
  .footer__links li:first-child:before {
    display: none; }

/*
 * User Pages
 */
#sortable img {
  cursor: move; }
  #sortable img:hover {
    cursor: move; }

#my-dropzone-container {
  border: 2px dashed #e11118;
  padding: 50px 0;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold; }

.dashboard-listitems {
  list-style-type: none;
  margin: 0;
  margin-bottom: 3em;
  padding: 0; }
  .dashboard-listitems li {
    border: 1px solid #504e53;
    padding: 5px 5px 5px 20px;
    line-height: 35px;
    font-weight: bold; }

.attachment {
  position: relative; }
  .attachment .btn-delete {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    background: rgba(255, 255, 255, 0.5); }
    .attachment .btn-delete:after, .attachment .btn-delete:before {
      position: absolute;
      left: 9px;
      top: 2px;
      content: ' ';
      height: 15px;
      width: 2px;
      background-color: #666; }
    .attachment .btn-delete:after {
      transform: rotate(45deg); }
    .attachment .btn-delete:before {
      transform: rotate(-45deg); }
    .attachment .btn-delete:hover {
      background: rgba(255, 255, 255, 0.8); }
    .attachment .btn-delete:hover:after .attachment .btn-delete:hover:before {
      background-color: #333; }

/*
 *	IMPORTS
 */
@font-face {
  font-family: 'fontello';
  src: url("/font/fontello.eot?55277003");
  src: url("/font/fontello.eot?55277003#iefix") format("embedded-opentype"), url("/font/fontello.woff2?55277003") format("woff2"), url("/font/fontello.woff?55277003") format("woff"), url("/font/fontello.ttf?55277003") format("truetype"), url("/font/fontello.svg?55277003#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/font/fontello.svg?55277003#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-cancel:before {
  content: '\e800'; }

/* '' */
.icon-phone:before {
  content: '\e801'; }

/* '' */
.icon-mail:before {
  content: '\e802'; }

/* '' */
.icon-twitter:before {
  content: '\f099'; }

/* '' */
.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
.icon-behance:before {
  content: '\f1b4'; }

/* '' */
.icon-share:before {
  content: '\f1e0'; }

/* '' */
.icon-facebook-official:before {
  content: '\f230'; }

/* '' */
.icon-pinterest:before {
  content: '\f231'; }

/* '' */
/*
 *	WEBSHOP STANDAARD CSS
 */
/* 
 *	CART ITEM
 */
.ws-cart-item {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0; }
  .ws-cart-item:last-of-type {
    border-bottom: 1px solid #f0f0f0; }

.ws-cart-item__img {
  -ms-flex: 1 0 17%;
  flex: 1 0 17%;
  max-width: 17%; }

.ws-cart-item__details {
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: 1rem; }

.ws-cart-item__actions {
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  max-width: 33%;
  padding-left: 1rem; }
  .ws-cart-item__actions .price {
    margin-bottom: 1rem; }
    .ws-cart-item__actions .price::after {
      content: '';
      display: table;
      clear: both; }
  .ws-cart-item__actions .amount {
    float: left; }
    .ws-cart-item__actions .amount input {
      display: inline-block;
      margin-left: .5rem;
      width: 60px; }
  .ws-cart-item__actions .remove {
    clear: both; }

/*
*	CART LIST
*/
.ws-cart-dropdown {
  font-size: .8em;
  padding: 1rem; }
  .ws-cart-dropdown .ws-cart-list {
    max-width: 350px; }
    .ws-cart-dropdown .ws-cart-list .ws-cart-item p {
      margin-bottom: 5px; }
  .ws-cart-dropdown .ws-cart-item__actions .price {
    margin-bottom: 0; }
  .ws-cart-dropdown .ws-cart-item {
    padding: .5rem 0; }
  .ws-cart-dropdown .ws-cart-actions {
    padding: 1rem 0 0 0; }
    .ws-cart-dropdown .ws-cart-actions:after {
      content: '';
      display: block;
      clear: both; }
  .ws-cart-dropdown .ws-cart-total {
    padding: 10px;
    background-color: #F8F8F8; }

.custom-input {
  width: auto;
  display: inline-block;
  max-width: 60px;
  margin-left: .5rem;
  padding: .375rem .75rem .375rem .75rem;
  color: #55595c;
  vertical-align: middle;
  background: #fff; }

/*
 *	CART INFO
 */
.ws-info-box {
  padding: 1rem;
  background-color: #ddd; }
  .ws-info-box ul {
    padding-left: 0; }
  .ws-info-box li {
    list-style: none; }
  .ws-info-box .price {
    float: right; }
  .ws-info-box .table td {
    border-top: none; }
    .ws-info-box .table td:last-child {
      text-align: right; }
  .ws-info-box tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .ws-info-box tfoot tr:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* 
 *	CONTINUE
 */
.ws-continue {
  padding: 1rem 1rem .5rem;
  background-color: #ddd; }
  .ws-continue .btn {
    width: 100%; }

.ws-continue__logos {
  display: block;
  margin-bottom: 0;
  padding: 0;
  font-size: 0; }
  .ws-continue__logos li {
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .5rem; }

/*
 *	PROGRESS
 */
.ws-progress .nav {
  width: 100%;
  display: -ms-flexbox;
  display: flex; }

.ws-progress .nav-item {
  -ms-flex: 1 0 25%;
  flex: 1 0 25%; }

.ws-progress .nav-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #ddd; }
  .ws-progress .nav-link.active {
    font-weight: bold;
    font-style: italic; }

.ws-progress span.nav-link {
  color: rgba(0, 0, 0, 0.5); }

.ws-progress .nav-item:nth-child(2) .nav-link {
  background-color: #e5e5e5; }

.ws-progress .nav-item:nth-child(3) .nav-link {
  background-color: #ececec; }

.ws-progress .nav-item:nth-child(4) .nav-link {
  background-color: #f4f4f4; }

/*
 *	PAYMENT OPTIONS
 */
.ws-payment-options {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }
  .ws-payment-options li {
    display: inline-block;
    width: 50px;
    margin: 0 3px 5px 0; }
  .ws-payment-options img {
    max-width: 100%; }

.ws-pay-icon {
  width: 35px;
  vertical-align: middle; }

@media (max-width: 575px) {
  .ws-progress .nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ws-progress .nav-item {
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
  .ws-progress .nav-link {
    padding: .5rem; } }

/*
 *	Tijdelijke hacks en bugfixes
 */
/*
 *	CUSTOM RESPONSIVE CODE
 */
@media (min-width: 1500px) {
  .container {
    width: 1480px;
    max-width: 100%; } }

@media (max-width: 1199px) {
  /*
	 *	NAV
	 */
  .nav-wrapper {
    z-index: 99;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    overflow-y: scroll;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center; }
    .nav-wrapper .nav {
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      max-width: 100%;
      display: block;
      text-align: center;
      border-top: 1px solid rgba(255, 255, 255, 0.2); }
    .nav-wrapper .nav-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .nav-wrapper .nav-link {
      color: #fff; }
      .nav-wrapper .nav-link:hover, .nav-wrapper .nav-link:active {
        color: #e11118; }
  .is-nav-open .nav-wrapper {
    opacity: 1;
    pointer-events: auto;
    z-index: 99;
    transition: opacity .15s ease-out; }
  .nav--main .nav-item {
    margin-right: 0; }
  .finger-print {
    display: block;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  .open-nav {
    display: inline-block; }
  .close-nav {
    display: block;
    margin-bottom: 15px; }
    .close-nav:hover, .close-nav:active {
      color: #e11118; } }

@media (max-width: 767px) {
  .agenda__img {
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    max-width: 70%; }
  .agenda__desc {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%; }
    .agenda__desc .title {
      margin-bottom: 15px; }
    .agenda__desc .date {
      margin-bottom: 10px; } }

@media (max-width: 575px) {
  /*
	 *	AGENDA
	 */
  .agenda {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .agenda__img {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 15px; }
  .agenda__desc {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%; }
  /* 
	 *	HEADER
	 */
  .logo {
    width: 130px; }
  .header {
    padding-bottom: 5px; }
    .header .row {
      -ms-flex-align: center !important;
      align-items: center !important; }
  .col--logo {
    -ms-flex-order: 0;
    order: 0; }
  .col--lang {
    -ms-flex-order: 1;
    order: 1; }
    .col--lang .nav-link {
      padding: .5em; }
  .col--nav {
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-order: 2;
    order: 2;
    text-align: right; }
  .nav--link .nav-link {
    padding: .5em; }
  /*
	 *	GENERAL
	 */
  .section {
    padding: 30px 0; }
  .block-title {
    margin-bottom: 30px;
    padding: 15px;
    font-size: 1.2rem;
    min-width: 0; } }

@media (max-width: 455px) {
  .col--nav {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px; }
  .open-nav {
    width: 100%;
    max-width: 100%; } }

@media (max-width: 310px) {
  .nav--lang .nav-item + .nav-item {
    margin-left: 4px; }
    .nav--lang .nav-item + .nav-item::before {
      content: none; }
  .nav--lang .nav-link {
    padding: 3px 2px; }
  .artpool-filters select {
    width: 100%; } }

@media (max-width: 270px) {
  .logo {
    width: 100px; } }

/*# sourceMappingURL=app.css.map */
